import React from "react";
import { styled } from '@mui/material/styles';
import _, { before } from "lodash";

import { BgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"

import { Link } from "gatsby"

import { getEntityUrl, getOverlayImages } from "../../util";
import Wrapper from "../../components/Wrapper";


import "core-js/proposals/string-match-all";//polyfill for old Androids; TODO - move away from here

import "./app.css";

const Root = styled('div')(({ theme }) => ({
    height: "100vh",
}));
Root.defaultProps = {
    "data-id": "Root"
  };

const BackgroundImage = styled(BgImage)(({ theme }) => ({
    flexGrow: 0,
    backgroundSize: "cover",
    width:"100%",
    height: '100vh',

}));
BackgroundImage.defaultProps = {
    "data-id": "BackgroundImage"
  };

const Logo = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 16,
    right: 16,
    background: 'url(/images/Schirn_neg.png) no-repeat',
    backgroundSize: 'contain',
    width: 186,
    color: 'transparent',
    aspectRatio: '1 / 1',
}));
Logo.defaultProps = {
    "data-id": "Logo"
  };

const Claim = styled('div')(({ theme }) => ({
    position: 'absolute',
    /**top: '50vh',**/
    bottom: '100px',
    transform: 'translateY(-50%)',
    left: '3%',
    height: 83,
    width: '94%',
    background: 'url(/images/Chagall_headline_neg_200mm.png) no-repeat',
    backgroundSize: 'contain',
    zIndex: 9999999,
    color: 'transparent',
}));

const Children = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: 20,
    left: '4%',
}));

const ChildLink = styled(Link)(({ theme }) => ({
    fontFamily: 'Schirnblack',
    textTransform: 'uppercase',
    background: 'rgb(163 160 200 / 0.5)',
    backdropFilter: 'blur(20px)',
    padding: '9px 15px',
    marginTop: 6,
    display: 'block',
    borderRadius: 4,
    width: '100%',
    minWidth: '57vw',
    textDecoration: 'none',
    color: '#fff',
    fontSize: '1.3rem',
}));

const ChildDiv = styled('div')(({ theme }) => ({
}));

const EntityPage = (props) => {

    const { entity, pageContext, selectedModule, files } = props;
    const { strapiChildren: children } = entity;

    const image = getImage(entity.largeImage?.localFile);
    const { defaultLocale } = pageContext;

    return <Root>
        <Wrapper className="wrapper" {...props} topbar={false} title={entity.title} transparentAppbar={true}>
            <div className="wrapper2" style={{ overflowX: 'hidden' }/*TODO - var*/}>
                <BackgroundImage className="background"  image={image}>
                    <Logo>{entity.title}</Logo>
                    <Claim className="claim______">{entity.shortDesc}</Claim>
                    <Children className="linkliste" >
                        {children.map((child, n) => {
                            const url = getEntityUrl({ entity: child, defaultLocale });

                            return <ChildLink className="childlink___" to={url}>{child.title}</ChildLink>
                        })}
                    </Children>
                </BackgroundImage>

            </div>
        </Wrapper>
    </Root>
}

export default EntityPage;